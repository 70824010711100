<template>
  <div class="kt-widget3__item">
    <div class="kt-widget3__header">
      <div v-b-tooltip :title="tips ? tips : ''" class="kt-widget3__user-img">
        <b-img class="kt-widget3__img" :src="avatar || ''" />
      </div>
      <div v-b-tooltip :title="tips ? tips : ''" class="kt-widget3__info">
        <a class="kt-widget3__username">
          {{ nickname }}
        </a><br>
        <span v-if="timeAgo" class="kt-widget3__time">{{ timeAgo }}</span>
      </div>
      <span class="kt-widget3__status "><slot name="status"></slot></span>
    </div>
    <div class="kt-widget3__body">
      <p class="kt-widget3__text">
        {{ content}}
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "media",
    props: {
      avatar: String,
      nickname: String,
      timeAgo: String,
      approval: Boolean,
      content: String,
      tips: String
    }
  }
</script>

<style>

</style>
